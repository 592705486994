<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-container>
        <v-row justify="center" class="mb-5">
          <h1>About Baguette</h1>
        </v-row>
        <v-row justify="center" class="mt-5">
          <v-col align="center">
            <p style="max-width: 700px; text-align: justify">
              Baguette is a marketplace for rare music collectibles. A place for
              artists to sell never-before-heard songs or original demos of hit
              songs.
            </p>
            <p style="max-width: 700px; text-align: justify">
              A virtual record is pressed for each song and then sold in the
              form of Non-Fungible Tokens to the highest bidder. Baguette utilizes the Flow blockchain allowing amazingly low cost gas fees.
            </p>
            <p style="max-width: 700px; text-align: justify">
              Here’s the catch... Auctions are held without hearing the track
              and potential buyers get nothing but a short description of the
              song from the artist before bidding.
            </p>
            <p style="max-width: 700px; text-align: justify">
              The highest bidder receives the NFT and becomes the internet
              gatekeeper to the song. The recordings cannot be released, even by
              the artist, until the new owner decides to make the song public.
              Even the owner will not know what the recording sounds like until
              they do so.
            </p>
            <p style="max-width: 700px; text-align: justify">
              Do they hoard it like a vintage bottle of wine and wait for a
              special occasion? Sell it on the secondary market for a quick
              turnaround? Or do they “break bread” and release the song for the
              world to hear? The choice is theirs and only they have the key.
              Welcome to Baguette.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",
  data: () => ({}),
  methods: {},
};
</script>